import { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";

import Loader from "../components/Loader";
import Notification from "../components/Notification";
import DynamicFooter from "../components/DynamicFooter";

const Support = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    document.title = "BitInverse | Support";
  }, []);

  // -------------- Open support ticket --------------------------
  const handleSendMail = (e) => {
    e.preventDefault();



  
  };

  return (
    <>
      <LoggedInNavbar />
      <Container className="signupWrapper">
        {showAlert ? (
          <Notification
            message={message}
            color={color}
            setShowAlert={setShowAlert}
          />
        ) : (
          ""
        )}
        <div className="signUp">
          <h3 id="formTitle">Support Form</h3>

          <p>
            <strong>Note: </strong>
            For transaction related issues, please add the transaction's{" "}
            <span style={{ color: "red" }}>Reference Id </span>
            to your message.
          </p>

          <Form onSubmit={(e) => handleSendMail(e)}>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">Email</Form.Label>
              <Form.Control
                value={email}
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">Subject</Form.Label>
              <Form.Control
                value={subject}
                type="text"
                placeholder="Enter subject"
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">Message</Form.Label>
              <Form.Control
                value={supportMessage}
                as="textarea"
                placeholder="Enter message"
                onChange={(e) => setSupportMessage(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label id="formLabel">Attachment</Form.Label>
              <Form.Control type="file" />
              <span id="sideNote">
                Allowed File Extensions: .jpg, .jpeg, .png, .pdf, .doc, .docx
              </span>
            </Form.Group>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Submit now"}
            </button>
          </Form>
        </div>
      </Container>
      {/* <Footer /> */}

      <DynamicFooter />
    </>
  );
};

export default Support;
