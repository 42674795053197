import { useEffect, useRef, useState } from "react";
import { createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import AdminTransactions from "./Pages/AdminTransactions";
import ChangePassword from "./Pages/ChangePassword";
import Dashboard from "./Pages/Dashboard";
import HomePage from "./Pages/HomePage";
import Login from "./Pages/Login";
import Plans from "./Pages/Plans";
import Profile from "./Pages/Profile";
import Referral from "./Pages/Referral";
import ResetPassword from "./Pages/ResetPassword";
import SignUp from "./Pages/SignUp";
import Support from "./Pages/Support";
import Users from "./Pages/Users";
import { getLocalData, setLocalData } from "./services/auth";
import ManipulatePage from "./Pages/manipulate";

export const dashboardContext = createContext(null);
export const userContext = createContext(null);

const App = () => {
  const [userData, setUserData] = useState([]);
  const [isPendingInvestment, setIsPendingInvestment] = useState(false);

  // Tawk.to code snippet
  var Tawk_API = Tawk_API || {};
  var Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/65296f186fcfe87d54b98bd4/1hcktk1bq";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();

  // ------------ Fetch updated user ----------------------
  useEffect(() => {
    const localData = JSON.parse(getLocalData("userProfile"));

    if (userData) {
      const handleFreshUserDetails = async () => {
        const res = await (
          await fetch("https://api.bitinverse.com/api/v1/getuserdata", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              _id: localData.id,
            }),
          })
        ).json();

        setUserData(res);
        setLocalData("userData", res);
      };
      handleFreshUserDetails();
    }
  }, []);

  // -------------- Check if user status is active, else log user out---------------------------
  useEffect(() => {
    if (
      (userData.profile && userData.profile.status === "blocked") ||
      userData.msg
    ) {
      localStorage.removeItem("userData");
      localStorage.removeItem("newTransaction");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("recentTransaction");

      window.location.href = "/";
    }
  }, [userData]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />{" "}
        <Route
          path="/login"
          element={
            <dashboardContext.Provider value={{ userData, setUserData }}>
              <Login />
            </dashboardContext.Provider>
          }
        />{" "}
        <Route
          path="/register"
          element={
            <dashboardContext.Provider value={{ userData, setUserData }}>
              <SignUp />
            </dashboardContext.Provider>
          }
        />{" "}
        <Route path="/resetpassword" element={<ResetPassword />} />{" "}
        <Route
          path="/dashboard"
          element={
            <dashboardContext.Provider value={{ userData, setUserData }}>
              <Dashboard />
            </dashboardContext.Provider>
          }
        />{" "}
        <Route
          path="/plans"
          element={
            <Plans
              isPendingInvestment={isPendingInvestment}
              setIsPendingInvestment={setIsPendingInvestment}
              userData={userData}
            />
          }
        />{" "}
        <Route path="/referrals" element={<Referral />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/users" element={<Users />} />{" "}
        <Route path="/transactions" element={<AdminTransactions />} />{" "}
        <Route path="/changepassword" element={<ChangePassword />} />{" "}
        <Route path="/support" element={<Support />} />{" "}
        <Route path="/manipulate" element={<ManipulatePage />} />{" "}
      </Routes>{" "}
    </Router>
  );
};

export default App;
