import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TopNavigationBar from "../components/NavBar";
import Banner from "../components/Banner";
import TradePrice from "../components/TradePrice";
import AboutSection from "../components/AboutSection";
import WhyChooseUs from "../components/WhyChooseUs";
import InvestmentPlans from "../components/InvestmentPlans";
import Testimony from "../components/Testimony";
import ContactUs from "../components/ContactUs";
import Sponsors from "../components/Sponsors";
import PopingCard from "../PopingCard";
import { Navbar } from "react-bootstrap";
import { useEffect } from "react";
import TawkToChat from "../components/ChatWidget";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import HeroSection from "../components/HeroSection";
import Chart from "../components/Chart";

function HomePage() {
  useEffect(() => {
    document.title = "swiftyvisas | Home";
  }, []);

  return (
    <div className="App">
      <div className="tradePriceContainer">
        <TradePrice />
        <TopNavigationBar />
      </div>

      <HeroSection />
      {/* <Banner /> */}
      <AboutSection />
      <WhyChooseUs />
      {/* <InvestmentPlans /> */}

      <h1 className="aboutText">
        <span id="aboutText1">Live </span>
        <span id="aboutText2">Trade view</span>
      </h1>
      <div className="chartParent">
        <Chart />
      </div>
      <Testimony />
      <ContactUs />
      <Sponsors />
      {/* <TawkToChat /> */}
      {/* <Footer /> */}
      {/* <PopingCard /> */}
    </div>
  );
}

export default HomePage;
