import { Col, Container, Form, Row } from "react-bootstrap";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { getLocalData, setLocalData } from "../services/auth";
import { useLocation } from "react-router";
import Notification from "../components/Notification";

const ManipulatePage = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(location.state && location.state);
  const [refresh, setRefresh] = useState(true);

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [totalWalletBalance, setTotalWalletBalance] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [investmentProfit, setInvestmentProfit] = useState(0);
  const [availableWithdrawal, setAvailableWithdrawal] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);

  const data = {
    email: userData.email,
    totalWalletBalance,
    totalInvestment,
    investmentProfit,
    availableWithdrawal,
    totalWithdrawal,
  };

  // ================= Manipulate ==============================
  const handleManipulate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (
        !totalWalletBalance &&
        !totalInvestment &&
        !investmentProfit &&
        !availableWithdrawal &&
        !totalWithdrawal
      ) {
        throw new Error("Cannot update empty fields");
      }

      const result = await fetch("https://api.bitinverse.com/api/v1/manipulate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!result.ok) {
        throw new Error("Failed to update data");
      }

      const responseData = await result.json();
      setLocalData("manipulateData", responseData);
      setRefresh(!refresh);

      setTotalWalletBalance(0);
      setInvestmentProfit(0);
      setTotalInvestment(0);
      setTotalWithdrawal(0);
      setAvailableWithdrawal(0);
      setIsLoading(false);
    } catch (error) {
      console.error("Error manipulating data:", error);
      // Handle error messaging or logging here
      setMessage(error.message);
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchedData = JSON.parse(getLocalData("manipulateData"));
    setUserData(fetchedData);
  }, [refresh]);

  return (
    <>
      <LoggedInNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="signupWrapper">
        <div className="profile">
          <Form onSubmit={handleManipulate}>
            {/* --------------Avatar Section ------------ */}
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={userData.email}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Total Wallet Balance</Form.Label>
                  <p style={{ color: "gray" }}>
                    Please note that the Total Wallet Balance comprises the
                    Total Investment, Total Investment Profit, Referral
                    Earnings, and Signup Bonus.
                  </p>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="number"
                    placeholder={`$${(
                      parseInt(userData.totalWalletBalance) +
                      parseInt(userData.totalInvestment) +
                      parseInt(userData.investmentProfit) +
                      50 +
                      parseInt(userData.referralEarning)
                    ).toLocaleString()}`}
                    onInput={(e) => setTotalWalletBalance(e.target.value)}
                    value={totalWalletBalance === 0 ? "" : totalWalletBalance}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Total Investment</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="number"
                    placeholder={`$${userData.totalInvestment.toLocaleString()}`}
                    onInput={(e) => setTotalInvestment(e.target.value)}
                    value={totalInvestment === 0 ? "" : totalInvestment}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">
                    Total Investment Profit
                  </Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="number"
                    placeholder={`$${userData.investmentProfit.toLocaleString()}`}
                    onInput={(e) => setInvestmentProfit(e.target.value)}
                    value={investmentProfit === 0 ? "" : investmentProfit}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Available Withdrawal</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="number"
                    placeholder={`$${userData.availableWithdrawal.toLocaleString()}`}
                    onInput={(e) => setAvailableWithdrawal(e.target.value)}
                    value={availableWithdrawal === 0 ? "" : availableWithdrawal}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Total Withdrawal</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="number"
                    placeholder={`$${userData.totalWithdrawal.toLocaleString()}`}
                    onInput={(e) => setTotalWithdrawal(e.target.value)}
                    value={totalWithdrawal === 0 ? "" : totalWithdrawal}
                  />
                </Form.Group>
              </Col>
            </Row>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default ManipulatePage;
