import { useState, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import FormsNavbar from "../components/FormsNavbar";
import { useEffect } from "react";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { useNavigate } from "react-router-dom";
import { dashboardContext } from "../App";
import { getLocalData, setLocalData } from "../services/auth";
import DynamicFooter from "../components/DynamicFooter";

const Login = () => {
  const { userData, setUserData } = useContext(dashboardContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState([]);
  const [captcha, setCaptcha] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    document.title = "BitInverse | Login";
  }, []);

  useEffect(() => {
    const userData = JSON.parse(getLocalData("userData"));
    if (userData) return navigate("/dashboard");
  }, []);

  // ------------ Generate Captcha --------------------------
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 9);
  };

  useEffect(() => {
    const nums = [];

    for (let i = 0; i < 6; i++) {
      nums.push(generateRandomNumber());
    }
    setCode(nums);
  }, []);

  // --------------- LOGIN -------------------------
  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      setIsLoading(true);
  
      // Check captcha
      if (code.join("") !== captcha) {
        throw new Error("Captcha is incorrect");
      }
  
      // Make API call to login
      const response = await fetch("https://api.bitinverse.com/api/v1/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
  
      // Check if response is successful
      if (!response.ok) {
        throw new Error("Invalid credentials");
      }
  
      const result = await response.json();
  
      // Check if login was successful
      if (result.profile) {
        // Update state and local storage
        setUserData(result);
        setLocalData("userData", result);
        setLocalData("userProfile", result.profile);
  
        // Check for pending investments
        const isPendingInvestment = result.transaction.some(
          ({ status }) => status === "pending"
        );
        setLocalData("isPendingInvestment", isPendingInvestment);
  
        // Display success message
        setMessage("Login successful");
        setColor("success");
        setShowAlert(true);
        setIsLoading(false);
  
        // Navigate to dashboard
        navigate("/dashboard");
      } else {
        // Display error message for invalid credentials
        setMessage("Invalid credentials");
        setColor("danger");
        setShowAlert(true);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle any errors that occur during the login process
      console.error("Login error:", error);
      setMessage(error.message || "An unexpected error occurred");
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };
  

  return (
    <div className="login">
      <FormsNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="formContainer">
        <div className="formHolder">
          <h3 id="formTitle">Login</h3>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="formLabel">Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter email"
                className="shadow-none"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="formLabel">Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Password"
                className="shadow-none"
                value={password}
                onInput={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="formLabel">Captcha</Form.Label>
              <div className="captchaBox">
                <div className="captchaContainer">
                  <span id="code1">{code[0]}</span>
                  <span id="code2">{code[1]}</span>
                  <span id="code3">{code[2]}</span>
                  <span id="code4">{code[3]}</span>
                  <span id="code5">{code[4]}</span>
                  <span id="code6">{code[5]}</span>
                </div>
              </div>
              <Form.Control
                required
                type="number"
                placeholder="Enter code"
                className="shadow-none"
                value={captcha}
                onInput={(e) => setCaptcha(e.target.value)}
                autocomplete="off"
              />
            </Form.Group>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Login"}
            </button>
          </Form>

          <Row>
            <Col>
              <div style={{ fontSize: "14px" }}>
                <span>Don't have an account? </span>
                <NavLink to="/register">Signup</NavLink>
              </div>
            </Col>
            <Col>
              <div style={{ fontSize: "14px" }}>
                <span>Forgotten Password? </span>
                <NavLink to="/resetpassword">Reset now</NavLink>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <DynamicFooter />

      {/* <Footer /> */}
    </div>
  );
};

export default Login;
