import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData, setLocalData } from "../services/auth";
import FetchLoader from "../components/FetchLoader";

const Users = () => {
  const navigate = useNavigate();
  const [allusers, setAllUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);

  const [userToDeleteEmail, setUserToDeleteEmail] = useState("");
  const [userToDeleteRole, setUserToDeleteRole] = useState("");
  const [adminConfirm, setAdminConfirm] = useState("");

  const [userRole, setUserRole] = useState([]);

  const [emailToUpdate, setEmailToUpdate] = useState("");
  const [valueToUpdate, setValueToUpdate] = useState("");
  const [userToUpdateRole, setUserToUpdateRole] = useState("");
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    document.title = "BitInverse | Users";
  }, []);

  // -------------- Check Current User Role to see if eligible to view the users page ------------
  useEffect(() => {
    const currentUser = JSON.parse(getLocalData("userData"));
    if (
      currentUser.profile.role === "super" ||
      currentUser.profile.role === "admin"
    ) {
      return setUserRole(currentUser.profile.role);
    }
    navigate("/dashboard");
  }, []);

  const handleCloseModal = () => setShowModal(false);
  const handleCloseRole = () => setShowRoleModal(false);

  const handleClose = () => setShow(false);

  const handleShow = (email, role) => {
    setShow(true);
    setUserToDeleteEmail(email);
    setUserToDeleteRole(role);
  };

  const handleManipulate = (
    email,
    totalInvestment,
    totalWalletBalance,
    investmentProfit,
    referralEarning,
    availableWithdrawal,
    totalWithdrawal
  ) => {
    setLocalData("manipulateData", {
      email,
      totalInvestment,
      totalWalletBalance,
      investmentProfit,
      referralEarning,
      availableWithdrawal,
      totalWithdrawal,
    });
    navigate("/manipulate", {
      state: {
        email,
        totalInvestment,
        totalWalletBalance,
        investmentProfit,
        referralEarning,
        availableWithdrawal,
        totalWithdrawal,
      },
    });
  };

  // ---------- Handle user deletion ------------------------
  const deleteUser = async (email, role) => {
    try {
      if (email !== adminConfirm) {
        console.log("lol");
        return;
      }

      if (role === "super") {
        console.log("lol");
        return;
      }

      const result = await (
        await fetch("https://api.bitinverse.com/api/v1/deleteuser", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();
    } catch (error) {}
  };

  /* ----------- Modal for deleting ---------------- */
  const modal = (userToDeleteEmail, userToDeleteRole) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">
                Please enter{" "}
                <span style={{ color: "#022E1F" }}>{userToDeleteEmail}</span> to
                confirm action.
              </Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter the user's email"
                onChange={(e) => setAdminConfirm(e.target.value)}
              />
            </Form.Group>
            <Button
              type="submit"
              variant="danger"
              size="block"
              onClick={() => deleteUser(userToDeleteEmail, userToDeleteRole)}
            >
              Delete
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // -------- Get all users from the server ----------------
  useEffect(() => {
    setIsloading(true);
    const getAllUsers = async () => {
      const res = await (
        await fetch("https://api.bitinverse.com/api/v1/allusers")
      ).json();

      setAllUsers(res);
      setIsloading(false);
    };

    getAllUsers();
  }, []);

  // ========================================================
  // -------------- Handle update user data to update -----------------------
  const handleUpdateUser = async (e, email, role) => {
    setShowModal(true);
    const value = e.target.value;

    setValueToUpdate(value);
    setEmailToUpdate(email);
    setUserToUpdateRole(role);
  };

  // -------------- Handle update user -----------------------
  const updateStatus = async (e, email, value, role) => {
    e.preventDefault();
    setShowModal(false);

    try {
      if (role === "super") return "";
      const result = await (
        await fetch("https://api.bitinverse.com/api/v1/updateuserstatus", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email, value }),
        })
      ).json();

      setEmailToUpdate("");
      setValueToUpdate("");
      setUserToUpdateRole("");
    } catch (error) {
      console.log(error);
    }
  };

  /* ----------- Modal for Updating Status ---------------- */
  const updateModal = (email, value, userToUpdateRole) => {
    return (
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">
                Are you sure you want to make an update on{" "}
                <span style={{ color: "#022E1F" }}>{email}</span>?
              </Form.Label>
            </Form.Group>
            <Button
              type="submit"
              variant="success"
              size="block"
              onClick={(e) => updateStatus(e, email, value, userToUpdateRole)}
            >
              Yes!
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };
  // =============================================================

  const handleUpdateUserRoleData = (e, email, role) => {
    e.preventDefault();
    setShowRoleModal(true);
    const newRole = e.target.value;

    setEmailToUpdate(email);
    setUserToUpdateRole(role);
    setValueToUpdate(newRole);
  };

  const handleUpdateRole = async (
    e,
    emailToUpdate,
    userToUpdateRole,
    valueToUpdate
  ) => {
    e.preventDefault();

    try {
      if (userToUpdateRole === "super") {
        setShowRoleModal(false);
        return;
      }
      const result = await (
        await fetch("https://api.bitinverse.com/api/v1/updateuserrole", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ emailToUpdate, valueToUpdate }),
        })
      ).json();

      setEmailToUpdate("");
      setValueToUpdate("");
      setUserToUpdateRole("");
      setShowRoleModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  /* ----------- Modal for Updating Role ---------------- */
  const updateRoleModal = (emailToUpdate, userToUpdateRole, valueToUpdate) => {
    return (
      <Modal show={showRoleModal} onHide={handleCloseRole}>
        <Modal.Header closeButton>
          <Modal.Title>Update Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">
                Are you sure you want to make an update on{" "}
                <span style={{ color: "#022E1F" }}>{emailToUpdate}</span>?
              </Form.Label>
            </Form.Group>
            <Button
              type="submit"
              variant="success"
              size="block"
              onClick={(e) =>
                handleUpdateRole(
                  e,
                  emailToUpdate,
                  userToUpdateRole,
                  valueToUpdate
                )
              }
            >
              Yes!
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <LoggedInNavbar />

      {isloading ? <FetchLoader /> : ""}

      {/* ----------- Modal for deleting ---------------- */}
      {modal(userToDeleteEmail, userToDeleteRole)}
      {updateModal(emailToUpdate, valueToUpdate, userToUpdateRole)}
      {updateRoleModal(emailToUpdate, userToUpdateRole, valueToUpdate)}

      <Container className="referralPage" fluid>
        <h3 id="aboutText1">All Users</h3>
        <Table size="sm" responsive>
          <tr>
            <th>S/N</th>
            <th>Fullname</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Role</th>
            <th>Joined At</th>
            <th>Address(Crypto)</th>
            <th>Network(Crypto)</th>
            <th>Status</th>
            <th>Manipulate</th>
            <th>Delete</th>
          </tr>

          <>
            {allusers &&
              allusers.map(
                (
                  {
                    id,
                    firstName,
                    secondName,
                    email,
                    phone,
                    totalWalletBalance,
                    totalInvestment,
                    investmentProfit,
                    referralEarning,
                    role,
                    status,
                    cryptoWalletAddress,
                    cryptoWalletNetwork,
                    createdAt,
                    availableWithdrawal,
                    totalWithdrawal,
                  },
                  index
                ) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{`${firstName} ${secondName}`}</td>
                    <td>{email}</td>
                    <td>{phone}</td>
                    <td>
                      <select
                        disabled={
                          userRole && userRole === "super" ? false : true
                        }
                        name="role"
                        id="userRole"
                        onChange={(e) =>
                          handleUpdateUserRoleData(e, email, role)
                        }
                      >
                        <option value={role}>{role}</option>
                        <option value={role === "user" ? "admin" : "user"}>
                          {role === "user" ? "admin" : "user"}
                        </option>
                        <option value="super">
                          {role === "user" || role === "admin"
                            ? "super"
                            : "admin"}
                        </option>
                      </select>
                    </td>
                    <td>{createdAt.split("T")[0]}</td>
                    <td>{cryptoWalletAddress}</td>
                    <td>{cryptoWalletNetwork}</td>
                    <td>
                      <select
                        name="userStatus"
                        id="userRole"
                        onChange={(e) => handleUpdateUser(e, email, role)}
                      >
                        <option value={status}>{status}</option>
                        <option
                          value={status === "active" ? "blocked" : "active"}
                        >
                          {status === "active" ? "blocked" : "active"}
                        </option>
                      </select>
                    </td>

                    <td>
                      <Button
                        variant="success"
                        id="manipulateBtn"
                        size="sm"
                        onClick={() =>
                          handleManipulate(
                            email,
                            totalInvestment,
                            totalWalletBalance,
                            investmentProfit,
                            referralEarning,
                            availableWithdrawal,
                            totalWithdrawal
                          )
                        }
                      >
                        Manipulate
                      </Button>
                    </td>

                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        id="deleteBtn"
                        onClick={() => handleShow(email, role)}
                        disabled={
                          userRole && userRole === "admin" ? true : false
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                )
              )}
          </>
        </Table>
      </Container>
      {isloading ? "" : <Footer />}
    </>
  );
};

export default Users;
