import { useEffect, useState } from "react";
import { Alert, Container, Form, Modal } from "react-bootstrap";
import Loader from "./Loader";
import { useNavigate } from "react-router";

const CustomInvest = ({ userData }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [lastTransaction, setLastTransaction] = useState("");
  const [isPendingInvestment, setIsPendingInvestment] = useState(false);

  const handleClose = () => {
    setToShow(false);
    setIsLoading(false);
  };

  // -------------- Update current transaction on  pageload ---------------------
  useEffect(() => {
    const allTransaction = async () => {
      const updatedTransaction = userData.transaction;
      const allTransactionStatus = [];

      updatedTransaction.map(({ status }) => {
        allTransactionStatus.push(status);

        setIsPendingInvestment(allTransactionStatus.includes("pending"));
      });

      const pending =
        updatedTransaction[updatedTransaction.length - 1].status === "pending"
          ? true
          : false;
      setIsPendingInvestment(pending);
    };

    allTransaction();
  }, [userData]);

  // --------------- Invest -----------------------------
  const handleInvest = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (isPendingInvestment === true) {
      setToShow(true);
      return;
    }

    if (amount < 500) {
      setIsLoading(false);
      throw "Amount cannot be less than $500";
    }

    const res = await (
      await fetch("https://api.bitinverse.com/api/v1/invest", {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          _id: userData.profile.id,
          amount,
        }),
      })
    ).json();

    navigate("/dashboard");
  };

  return (
    <>
      <Modal show={toShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <Alert.Heading>Pending Investment</Alert.Heading>
            <p>
              We apologize for the inconvenience. Your investment is currently
              under review. Kindly wait patiently. If you continue to see this
              message after 30 minutes, please reach out to our customer care
              team.
            </p>
          </Alert>
        </Modal.Body>
      </Modal>

      {/* ------------------------------------- */}

      <Container className="formContainer">
        <div className="formHolder">
          <h3 id="formTitle">Investment</h3>

          <Form onSubmit={handleInvest}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              
              <span style={{color: "gray"}}>
                Enter your desired investment amount. Please note that
                investments must be at least $500.
              </span>
              <br /> <br />
              <Form.Label id="formLabel">Amount ($)</Form.Label>
              <br />
              <Form.Control
                required
                type="number"
                placeholder="Enter amount to Invest"
                className="shadow-none"
                value={amount}
                onInput={(e) => setAmount(e.target.value)}
              />
            </Form.Group>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Invest"}
            </button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CustomInvest;
