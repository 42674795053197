import LoggedInNavbar from "../components/LoggedInNavbar";
import Footer from "../components/Footer";
import {
  Col,
  Container,
  Form,
  Modal,
  Overlay,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";

import dollar from "../assets/dollar.png";
import cubes from "../assets/cubes.png";
import ref from "../assets/ref.png";
import withdraw from "../assets/withdraw.png";
import wallet from "../assets/wallet.png";
import clip from "../assets/clip.png";

import { useState, useRef, useContext, useEffect } from "react";
import { dashboardContext } from "../App";
import { useNavigate } from "react-router";
import { getLocalData, setLocalData } from "../services/auth";
import FetchLoader from "../components/FetchLoader";
import Loader from "../components/Loader";
import DynamicFooter from "../components/DynamicFooter";

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(dashboardContext);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [transactionId, setTransactionId] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [transactionAmount, setTransactionAmount] = useState(0);
  const [transactionProfit, setTransactionProfit] = useState(0);
  const [userId, setUserId] = useState(0);
  const [userConfirm, setUserConfirm] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalInvestmentProfit, setTotalInvestmentProfit] = useState(0);
  const [totalWithdrawable, setTotalWithdrawable] = useState(0);
  const [signupBonuse, setSignupBonuse] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const [isFetching, setIsfetching] = useState(false);

  useEffect(() => {
    document.title = "BitInverse | Dashboard";
  }, []);

  const hideModal = () => {
    setErr("");
    setShowModal(false);
  };

  // ---------- Check if logged in, if so then set userData ------------------
  useEffect(() => {
    const userData = JSON.parse(getLocalData("userData"));
    if (!userData || userData.msg) return navigate("/");
  }, []);

  // ------------ Function to copy referal link -------------------
  function copy() {
    navigator.clipboard.writeText(userData.profile.referralLink);
    setShow(!show);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }

  // ------------ Fetch updated user ----------------------
  useEffect(() => {
    const localData = JSON.parse(getLocalData("userProfile"));

    setIsfetching(true);
    // if (userData) {

    const handleFreshUserDetails = async () => {
      const res = await (
        await fetch("https://api.bitinverse.com/api/v1/getuserdata", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            _id: localData.id,
          }),
        })
      ).json();

      setUserData(res);
      setLocalData("userData", res);
      setIsfetching(false);
    };
    handleFreshUserDetails();
    // }
  }, []);

  // ------------------ Update user total investment and investment profit----------------------------
  useEffect(() => {
    let investment = 0;
    let investmentProfit = 0;
    let withdrawable = 0;
    let withdrawableProfit = 0;

    userData.transaction &&
      userData.transaction.map((item) => {
        if (
          (item.type === "investment" && item.status === "Static") ||
          item.status === "active"
        ) {
          investment += item.amount;
          investmentProfit += item.profit;
        }
      });

    userData.transaction &&
      userData.transaction.map((item) => {
        if (item.withdrawalable === true) {
          withdrawable += item.amount;
          withdrawableProfit += item.profit;
        }
      });

    userData.transaction &&
      userData.transaction.map((item) => {
        if (item.type === "Sign-up" && item.status === "Static") {
          setSignupBonuse(item.amount);
        }
      });

    setTotalInvestment(investment);
    setTotalInvestmentProfit(investmentProfit);
    setTotalWithdrawable(withdrawable + withdrawableProfit);
  }, [userData]);

  // ===================================================================

  const withdraw = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const delayedAction = () => {
      setShowModal(true);
      setIsLoading(false);
    };

    setTimeout(function () {
      delayedAction();
    }, 2000);
  };

  // ==================== Grab data ===========================
  const grabData = (
    transactionId,
    referenceId,
    transactionAmount,
    transactionProfit,
    userId
  ) => {
    setShowModal(true);
    setTransactionId(transactionId);
    setReferenceId(referenceId);
    setTransactionAmount(transactionAmount);
    setTransactionProfit(transactionProfit);
    setUserId(userId);
  };

  // -------------------------- Withdraw logic -----------------------
  const handleWithdraw = async (
    e,
    transactionId,
    referenceId,
    userConfirm,
    transactionAmount,
    transactionProfit,
    userId
  ) => {
    e.preventDefault();
    setIsLoading(true);

    const requestData = {
      userId,
      transactionId,
      requestForWithdrawal: true,
      withdrawable: false,
      pendingStatus: "pending",
      newWithdrawalAmount: transactionAmount + transactionProfit,
    };

    if (referenceId !== userConfirm) {
      setErr("Reference ID mismatch. Please verify and retry.");
      setIsLoading(false);
      return;
    }

    // --------------- Make requested withdrawal to true -----------------------
    const rat = await (
      await fetch("https://api.bitinverse.com/api/v1/handleUserwithdrawalrequest", {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
    ).json();

    setShowModal(false);
    setIsLoading(false);

    window.location.href = "/dashboard";
  };

  // ==================== Withdrawal Modal ===========================
  const withdrawModal = () => {
    return (
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "#ddd" }}>
            Your investment profit from PIMCO International is set to be
            deposited into your savings account in just 2 minutes, with
            transaction clearance confirmed.
          </p>
        </Modal.Body>
      </Modal>
    );
  };

  const allInvestment = userData.profile && userData.profile.totalInvestment;
  const profit = userData.profile && userData.profile.investmentProfit;
  const validateWithdrawal =
    userData.profile && userData.profile.availableWithdrawal > 0;

  const totalBalance =
    userData.profile &&
    userData.profile.referralEarning +
      userData.profile.totalWalletBalance +
      signupBonuse +
      allInvestment +
      profit;

  return (
    <div>
      {/* {userData.profile ? "" : <FetchLoader />} */}

      <LoggedInNavbar isFetching={isFetching} />

      {withdrawModal()}

      <Container fluid className="dashboard">
        <span id="dashboardLabel">Referral Link</span>

        <div className="clipSection" ref={target} onClick={copy}>
          <input
            type="text"
            readOnly
            className="clipboardinput"
            value={userData.profile && userData.profile.referralLink}
          />
          <div className="clipIconHolder">
            <img src={clip} alt="clip" id="clipIcon" />
          </div>
        </div>

        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Link copied!
            </Tooltip>
          )}
        </Overlay>

        <Row>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Wallet Balance</span>
                <h3>{`$${
                  userData.profile ? totalBalance.toLocaleString() : 0
                }`}</h3>
              </div>

              <img src={wallet} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments</span>
                <h3>{`$${
                  userData.profile ? allInvestment.toLocaleString() : 0
                }`}</h3>
              </div>

              <img src={cubes} alt="icon" />
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Investment(s) Profit</span>
                <h3>{`$${userData.profile ? profit.toLocaleString() : 0}`}</h3>
              </div>

              <img src={dollar} alt="icon" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="case">
              <div className="dashboardCard">
                <div className="dashboardCardContent">
                  <span>Available Withdrawal</span>
                  <h3>{`$${
                    userData.profile
                      ? userData.profile.availableWithdrawal.toLocaleString()
                      : 0
                  }`}</h3>
                </div>

                <button
                  className={`butt ${validateWithdrawal ? "" : "inactive"}`}
                  disabled={validateWithdrawal ? false : true}
                  onClick={withdraw}
                >
                  {isLoading ? <Loader /> : "Withdraw"}
                </button>
              </div>

              <div className="cover">
                <div className="partOne">
                  <span
                    style={{
                      color: "#F28705",
                      fontSize: 12,
                      width: "50%",
                    }}
                  >
                    Please note that the withdraw button is only active when
                    there are withdrawable funds available.
                  </span>
                </div>
                {/* <div className="partOne"></div> */}
              </div>
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Withdrawal</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalWithdrawal.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={wallet} alt="icon" />
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Referral Earning</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.referralEarning.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={ref} alt="icon" />
            </div>
          </Col>
        </Row>

        <br />

        <span id="dashboardLabel">Transactions</span>

        <div>
          <Table size="sm" responsive>
            <tr>
              <th>S/N</th>
              <th>Date</th>
              <th>Reference ID</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Profit</th>
              <th>Details</th>
              <th>Status</th>
              <th>Request Withdrawal</th>
            </tr>
            <>
              {userData.transaction &&
                userData.transaction.map(
                  (
                    {
                      id,
                      amount,
                      createdAt,
                      details,
                      profit,
                      referenceId,
                      requestedWithdrawal,
                      withdrawalable,
                      status,
                      type,
                      userId,
                      referralEarning,
                    },
                    index
                  ) => {
                    return (
                      <tr key={id}>
                        <td>{index + 1}</td>
                        <td>{createdAt.split("T")[0]}</td>
                        <td id="referrenceID">{referenceId}</td>
                        <td>{type}</td>
                        <td>{`$${amount.toLocaleString()}`}</td>
                        <td id="plusAmount">
                          {profit === 0 ? "" : `+$${profit.toLocaleString()}`}
                        </td>
                        <td>{details}</td>
                        <td>
                          <span
                            style={{
                              color:
                                status === "pending"
                                  ? "rgb(211, 175, 16)"
                                  : status === "invalid"
                                  ? "red"
                                  : status === "active"
                                  ? "#F76300"
                                  : "",
                              fontWeight: "600",
                            }}
                          >
                            {status}
                          </span>
                        </td>
                        <td>
                          <button
                            className={`butt ${
                              withdrawalable ? "" : "inactive"
                            }`}
                            disabled={withdrawalable ? false : true}
                            onClick={() =>
                              grabData(id, referenceId, amount, profit, userId)
                            }
                          >
                            Withdraw
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )}
            </>
          </Table>
        </div>
      </Container>
      {/* {userData.profile ? <Footer /> : ""} */}

      <DynamicFooter />
    </div>
  );
};

export default Dashboard;
