import LoggedInNavbar from "../components/LoggedInNavbar";
import Footer from "../components/Footer";
import InvestmentPlans from "../components/InvestmentPlans";
import { useEffect } from "react";
import CustomInvest from "../components/CustomInvest";

const Plans = ({ isPendingInvestment, userData, setIsPendingInvestment }) => {
  useEffect(() => {
    document.title = "BitInverse | Plans";
  }, []);

  const year = new Date().getFullYear();

  return (
    <>
      <LoggedInNavbar />
      {/* <InvestmentPlans
        isPendingInvestment={isPendingInvestment}
        setIsPendingInvestment={setIsPendingInvestment}
        userData={userData}
      /> */}
      {/* <Footer /> */}

      <CustomInvest userData={userData} />

      {/* <div className="homeFooter">
        <span>
          © 2017-{year} <span id="footerLogo">BitInverse</span>. All rights
          reserved
        </span>
      </div> */}
    </>
  );
};

export default Plans;
