const DynamicFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="homeFooter">
      <span>
        © 2017-{year} <span id="footerLogo">BitInverse</span>. All rights reserved
      </span>
    </div>
  );
};

export default DynamicFooter;
