import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import back from "../assets/back.png";

const FormsNavbar = () => {
  return (
    <Navbar bg="dark" variant="dark" fixed="top">
      <Container fluid>
        <NavLink to="/" className="noDecoration">
          <Navbar.Brand href="#" id="logo">
            {" "}
            <strong>
              <span id="aboutText1">Bit</span>
              <span id="aboutText2">Inverse</span>
            </strong>
          </Navbar.Brand>
        </NavLink>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <NavLink to="/" id="backHomeLink">
            <img src={back} alt="back" style={{ width: 32 }} /> Back Home
          </NavLink>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default FormsNavbar;
