import { Container, Dropdown, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getLocalData } from "../services/auth";
import TranslateBtn from "../components/TranslateBtn";
import InstaLoader from "../components/InstaLoader";

const LoggedInNavbar = ({ isFetching }) => {
  const navigate = useNavigate();

  // =============== Log user out =====================
  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("newTransaction");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("recentTransaction");

    navigate("/");
  };

  const adminLinks = () => {
    const data = JSON.parse(getLocalData("userData"));

    if (data === null || data.err || data.profile === null || data.msg) {
      localStorage.removeItem("userData");
      localStorage.removeItem("newTransaction");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("recentTransaction");
      window.location.href = "/";
    }

    if (data.profile.role === "super" || data.profile.role === "admin") {
      return (
        <>
          <Dropdown.Item>
            <Link id="loggedLink" to="/users">
              Users(Admin)
            </Link>
          </Dropdown.Item>

          {/* <Dropdown.Item>
            <Link id="loggedLink" to="/transactions">
              Transactions(Admin)
            </Link>
          </Dropdown.Item> */}
        </>
      );
    }
  };

  return (
    <>
      <div className="dynamicNavbar">
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          // fixed="top"
        >
          <Container fluid>
            <NavLink to="/" className="noDecoration">
              <Navbar.Brand href="#" id="logo">
                {" "}
                <strong>
                  <span translate="no" id="aboutText1">
                    Bit
                  </span>
                  <span translate="no" id="aboutText2">
                    Inverse
                  </span>
                </strong>
              </Navbar.Brand>
            </NavLink>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content-end flex-grow-1 ">
                <div className="loggedNav">
                  <NavLink id="loggedLink" to="/dashboard">
                    Dashboard
                  </NavLink>
                  <Dropdown>
                    <Dropdown.Toggle id="accountDropDown">
                      Account
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link id="loggedLink" to="/profile">
                          Profile
                        </Link>{" "}
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <Link id="loggedLink" to="/changepassword">
                          Change Password
                        </Link>{" "}
                      </Dropdown.Item>

                      {/* <Dropdown.Item>
                        <Link id="loggedLink" to="/support">
                          Support
                        </Link>
                      </Dropdown.Item> */}

                      {adminLinks()}

                      <Dropdown.Item id="loggedLink" onClick={handleLogout}>
                        {" "}
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <NavLink id="loggedLink" to="/plans">
                    {" "}
                    Plan
                  </NavLink> */}

                  <NavLink to="/referrals" id="loggedLink">
                    Referrals
                  </NavLink>
                </div>
              </Nav>
              <TranslateBtn />
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {isFetching ? <InstaLoader /> : ""}
      </div>
    </>
  );
};

export default LoggedInNavbar;
