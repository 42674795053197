import { Col, Container, Row } from "react-bootstrap";
import logo4 from "../assets/logo4.png";
import logo1 from "../assets/logo1.svg";
import logo2 from "../assets/logo2.svg";
import logo3 from "../assets/logo3.svg";

const year = new Date().getFullYear()

const Sponsors = () => {
  return (
    <>
      <Container className="sponsorContainer">
        <h1 className="aboutText">
          <span id="aboutText1">Partners </span>
        </h1>

        <Row>
          <Col className="center">
            <img src={logo4} alt="logo" id="sponsors" loading="lazy"/>
          </Col>
          <Col className="center">
            <img src={logo1} alt="logo" id="sponsors" loading="lazy"/>
          </Col>
          <Col className="center">
            <img src={logo2} alt="logo" id="sponsors" loading="lazy"/>
          </Col>
          <Col className="center">
            <img src={logo3} alt="logo" id="sponsors" loading="lazy"/>
          </Col>
        </Row>
      </Container>

      <div className="homeFooter">
        <span>
          © 2017-{year} <span id="footerLogo">BitInverse</span>. All rights
          reserved
        </span>
      </div>
    </>
  );
};

export default Sponsors;
